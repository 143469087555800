import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const mobileNavigationBreakpoint = 1024

export default () => ({
    isActive: false,
    timer: null,
    activeMegaMenuId: null,
    triangleOffset: 0,

    onResize() {
        if (window.innerWidth > mobileNavigationBreakpoint) {
            this.isActive = false
        }
    },

    showMobileMegaMenu(event, menuId) {
        const mobilemegamenuwrapper = document.querySelector('#mobilemegamenus')
        const menu = mobilemegamenuwrapper.querySelector(
            `#mobilemegamenu-${menuId}`
        )
        const currentMenu = mobilemegamenuwrapper.lastElementChild

        if (menu) {
            event.preventDefault()
            menu.classList.remove('-translate-x-full')
            menu.classList.add('translate-x-full')
            menu.parentNode.appendChild(menu)
            disableBodyScroll(menu, { reserveScrollBarGap: true })

            setTimeout(() => {
                currentMenu.classList.remove('translate-x-full')
                currentMenu.classList.add('-translate-x-full')
                menu.classList.remove('translate-x-full')
            })
        }
    },

    mobileMegaMenuBack() {
        const mobilemegamenuwrapper = document.querySelector('#mobilemegamenus')
        const currentMenu = mobilemegamenuwrapper.lastElementChild
        const menu = currentMenu.previousElementSibling

        menu.classList.remove('translate-x-full')
        menu.classList.add('-translate-x-full')
        mobilemegamenuwrapper.insertBefore(
            currentMenu,
            mobilemegamenuwrapper.firstElementChild
        )
        disableBodyScroll(menu, { reserveScrollBarGap: true })

        setTimeout(() => {
            currentMenu.classList.remove('-translate-x-full')
            currentMenu.classList.add('translate-x-full')
            menu.classList.remove('-translate-x-full')
        })
    },

    resetTimer() {
        this.timer = clearTimeout(this.timer)
    },

    onMouseEnter(event) {
        const currentId = event.target.parentNode.dataset.megamenu
        const offset = event.target.offsetLeft + event.target.offsetWidth / 2

        if (currentId) {
            this.triangleOffset = offset
        }

        this.activeMegaMenuId = currentId
        this.resetTimer()
    },

    onMouseLeave() {
        this.timer = setTimeout(() => (this.activeMegaMenuId = null), 300)
    },

    setUpMegaMenuTogglers() {
        const boundOnMouseEnter = this.onMouseEnter.bind(this)
        const boundOnMouseLeave = this.onMouseLeave.bind(this)
        const megamenutogglers = [
            ...document.querySelectorAll('.megamenu-togglers li a'),
        ]

        megamenutogglers.forEach((toggler) => {
            toggler.addEventListener('mouseenter', boundOnMouseEnter)
            toggler.parentNode.addEventListener('mouseleave', boundOnMouseLeave)
        })
    },

    init() {
        const boundOnResize = this.onResize.bind(this)

        this.$watch('isActive', (isActive) => {
            if (isActive) {
                window.addEventListener('resize', boundOnResize)
                disableBodyScroll(this.$refs.panel, {
                    reserveScrollBarGap: true,
                })
            } else {
                window.removeEventListener('resize', boundOnResize)
                enableBodyScroll(this.$refs.panel)
            }
        })

        this.$watch('activeMegaMenuId', (menuId) => {
            const menu = document.querySelector(`#megamenu-${menuId}`)

            if (!menu) {
                return
            }

            const viewport = window.innerWidth
            const width = menu?.offsetWidth
            const megaMenuOffset = viewport - 24 - width / 2
            const result = megaMenuOffset - this.triangleOffset

            menu.style.right = Math.max(0, result) + 'px'
        })

        this.setUpMegaMenuTogglers()
    },
})
