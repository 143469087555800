export default () => ({
    hasPrevious: false,
    hasNext: false,

    scroll(delta) {
        const track = this.$refs.track
        const width = track.children?.[1]?.offsetWidth

        track.scrollLeft += width * delta
    },

    onScrollEnd() {
        const offsetWidth = this.$refs.track.offsetWidth
        const scrollWidth = this.$refs.track.scrollWidth
        const scrollLeft = this.$refs.track.scrollLeft

        this.hasPrevious = scrollLeft !== 0
        this.hasNext = offsetWidth + scrollLeft < scrollWidth
    },

    init() {
        const boundOnScrollEnd = this.onScrollEnd.bind(this)

        boundOnScrollEnd()
        this.$refs.track.addEventListener('scrollend', boundOnScrollEnd)
    },
})
