import { Loader } from '@googlemaps/js-api-loader'
import { calculateDistance, translate } from '@/lib/helpers.js'
import { dialog } from '@/lib/dialog.js'

export default (apiKey, zoom = 11) => ({
    apiKey,
    zoom,
    map: null,
    markers: [],
    query: '',
    activePointId: null,
    hasGeoLocation: false,
    loadingGeoLocation: false,

    useDevicePosition() {
        this.loadingGeoLocation = true

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude
                const lng = position.coords.longitude
                const marker = this.getClosestMarker(lat, lng)

                if (marker) {
                    this.activePointId = marker.id
                }

                this.loadingGeoLocation = false
            },
            (error) => {
                const dialogTitle = translate('couldnotfindposition')
                const dialogText = error.message
                const dialogAccept = translate('ok')
                const dialogCancel = translate('cancel')

                dialog(
                    dialogTitle,
                    dialogText,
                    dialogAccept,
                    dialogCancel
                ).catch((error) => console.error(error))

                this.loadingGeoLocation = false
                console.error(error)
            }
        )
    },

    getClosestMarker(lat, lng) {
        return this.markers
            .sort((a, b) => {
                const aDistance = calculateDistance(
                    a.position.lat,
                    a.position.lng,
                    lat,
                    lng
                )
                const bDistance = calculateDistance(
                    b.position.lat,
                    b.position.lng,
                    lat,
                    lng
                )

                return aDistance - bDistance
            })
            .at(0)
    },

    init() {
        const mapElement = this.$refs.map
        const markersContainer = this.$refs.markers
        const loader = new Loader({
            apiKey: this.apiKey,
            version: 'weekly',
            libraries: ['maps', 'marker'],
        })

        this.hasGeoLocation = 'geolocation' in navigator

        loader
            .load()
            .then(() => {
                const map = new google.maps.Map(mapElement, {
                    mapId: Math.random().toString(36).substring(7),
                    zoom: this.zoom,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                })
                const bounds = new google.maps.LatLngBounds()
                const markers = [...markersContainer.children].map(
                    (markerElement) => {
                        const id = markerElement.dataset.id
                        const lat = markerElement.dataset.lat
                        const lng = markerElement.dataset.lng
                        const pin = markerElement.querySelector('[data-pin]')
                        const content = markerElement
                            .querySelector('[data-content]')
                            .innerHTML.trim()
                        const marker =
                            new google.maps.marker.AdvancedMarkerElement({
                                map: map,
                                position: {
                                    lat: parseFloat(lat),
                                    lng: parseFloat(lng),
                                },
                                content: pin,
                            })

                        marker.id = id

                        if (content) {
                            marker.infowindow = new google.maps.InfoWindow({
                                content: content,
                                maxWidth: 250,
                            })

                            google.maps.event.addListener(
                                marker,
                                'click',
                                () => (this.activePointId = marker.id)
                            )

                            google.maps.event.addListener(
                                marker.infowindow,
                                'closeclick',
                                () => (this.activePointId = null)
                            )
                        }

                        return marker
                    }
                )

                markers.forEach((marker) => {
                    bounds.extend(marker.position)
                })

                if (markers.length === 1) {
                    map.setCenter(bounds.getCenter())
                } else {
                    map.fitBounds(bounds)
                }

                this.map = map
                this.markers = markers
            })
            .catch((error) => {
                console.error(error)
            })

        this.$watch('activePointId', (id) => {
            this.markers.forEach((marker) => {
                if (marker.infowindow) {
                    if (marker.id === id) {
                        marker.infowindow.open(this.map, marker)
                        this.map.setCenter(marker.position)
                        this.map.setZoom(this.zoom)
                    } else {
                        marker.infowindow.close()
                    }
                }
            })
        })
    },
})
