import { scrollend } from 'scrollyfills'
import Alpine from 'alpinejs'
import header from '@/components/header.js'
import search from '@/components/search.js'
import video from '@/components/video.js'
import gallery from '@/components/gallery.js'
import accordion from '@/components/accordion.js'
import servicePointsMap from '@/components/servicePointsMap.js'
import embedVideo from '@/components/embed/video.js'

//
// Fonts
//
import '@fontsource-variable/sora'

//
// Styles
//
import '../css/style.css'

//
// Setup Alpine
//
window.Alpine = Alpine

//
// Setup Alpine components
//
Alpine.data('header', header)
Alpine.data('search', search)
Alpine.data('video', video)
Alpine.data('gallery', gallery)
Alpine.data('accordion', accordion)
Alpine.data('servicePointsMap', servicePointsMap)
Alpine.data('embedVideo', embedVideo)

//
// Initialize Alpine
//
Alpine.start()
